import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { Box, Icon } from '@mui/material';

import { App, NavigationLink, Page } from '@/types/generated';
import { IGoogleMap } from '@/components/GoogleMap/GoogleMap';

import { wrapper, locationIconStyles } from './HeaderOfficeLocation.styles';
import HeaderOfficeDetails from './HeaderOfficeDetails';
import NoOfficeTitle from './NoOfficeTitle';

export interface IHeaderOfficeLocation {
	isLargeDevice: boolean;
	withOfficeDetails?: boolean;
	headerData: NonNullable<App['header']>;
	isDarkHeader: boolean;
	locationNavigationLinks?: Array<NavigationLink | Page> | undefined;
	useHeaderLocationCards?: boolean | null;
	googleMapsForLocationCards?: IGoogleMap;
}

export default function HeaderOfficeLocation({
	isLargeDevice,
	headerData,
	withOfficeDetails = false,
	isDarkHeader,
	locationNavigationLinks,
	useHeaderLocationCards = false,
	googleMapsForLocationCards,
}: Readonly<IHeaderOfficeLocation>) {
	return (
		<Box component="section" data-test-id="section_header_office_location" sx={wrapper}>
			<Icon>
				<LocationOnOutlinedIcon sx={locationIconStyles({ isDarkHeader })} data-test-id="icon_location" />
			</Icon>
			{withOfficeDetails ? (
				<HeaderOfficeDetails
					isLargeDevice={isLargeDevice}
					locationBarFindOfficeUrl={headerData.locationBarFindOfficeUrl || ''}
					locationBarWithOutOfficeTitle={headerData.locationBarWithOutOfficeTitle || ''}
					isDarkHeader={isDarkHeader}
					locationNavigationLinks={locationNavigationLinks}
					locationBarWithOfficeTitle={headerData.locationBarWithOfficeTitle || 'Find Another Office'}
					useHeaderLocationCards={useHeaderLocationCards}
					googleMapsForLocationCards={googleMapsForLocationCards}
				/>
			) : (
				<NoOfficeTitle
					locationBarFindOfficeUrl={headerData.locationBarFindOfficeUrl || ''}
					locationBarWithOutOfficeTitle={headerData.locationBarWithOutOfficeTitle || ''}
					isLightText={isDarkHeader}
				/>
			)}
		</Box>
	);
}
